export default () => {

  /**
   * タッチイベントの振り分け
   * @type {Object}
   */
  const EVENT = {}
  if ('ontouchstart' in window) {
    EVENT.TOUCH_START = 'touchstart'
    EVENT.TOUCH_MOVE = 'touchmove'
    EVENT.TOUCH_END = 'touchend'
  } else {
    EVENT.TOUCH_START = 'mousedown'
    EVENT.TOUCH_MOVE = 'mousemove'
    EVENT.TOUCH_END = 'mouseup'
  }

  window.addEventListener('DOMContentLoaded', () => {

    // set table wrapper in blog content
    const blogContentBody = document.querySelector('.blogContent__body');
    if (blogContentBody) {
      const tables = blogContentBody.querySelectorAll('table');
      tables.forEach(table => {
        table.outerHTML = '<div class="tableWrapper">' + table.outerHTML + '</div>';
      });
    }

    // set contents card slider
    const contentsCardSliders = document.querySelectorAll('.contentsCardSlider');
    if (contentsCardSliders.length) {
      contentsCardSliders.forEach(contentsCardSlider => {
        const list = contentsCardSlider.querySelector('.contentsCardSlider__list');
        const items = contentsCardSlider.querySelectorAll('.contentsCardSlider__item');
        const thumbnails = contentsCardSlider.querySelectorAll('.contentsCardSlider__thumbnail');
        let prevTouchPosition = false;
    
        list.addEventListener(EVENT.TOUCH_START, e => {
          prevTouchPosition = {};
          prevTouchPosition['pageX'] = e.pageX ? e.pageX : e.changedTouches[0].pageX;
        });
        list.addEventListener(EVENT.TOUCH_END, e => {
          const pageX = e.pageX ? e.pageX : e.changedTouches[0].pageX;
          let currentIndex = -1;
          thumbnails.forEach(thumbnail => {
            if (thumbnail.classList.contains('is-current')) {
              currentIndex = Number(thumbnail.getAttribute('data-index'));
            }
          });
          if (pageX - prevTouchPosition.pageX > 0) {
            if (currentIndex > 0) {
              gotoSlide(currentIndex - 1);
            }
          } else if (pageX - prevTouchPosition.pageX < 0) {
            if (currentIndex < thumbnails.length - 1) {
              gotoSlide(currentIndex + 1);
            }
          }
          prevTouchPosition = false;
        });
        thumbnails.forEach(thumbnail => {
          const index = Number(thumbnail.getAttribute('data-index'));
          thumbnail.addEventListener('click', () => {
            gotoSlide(index);
          });
        });

        function gotoSlide(index) {
          thumbnails.forEach(thumbnail => {
            thumbnail.classList.remove('is-current');
          });
          thumbnails[index].classList.add('is-current');
          items.forEach(item => {
            item.classList.remove('is-current');
          });
          items[index].classList.add('is-current');
        }
      })
    }

    // side contents in Blog
    var estatesListElements = document.querySelectorAll('.js-estatesList');
    if (estatesListElements) {

      /**
      * 要素を生成
      * @param {Object} options   - オプション
      * @param {String}   tag       - タグ
      * @param {String}   text      - テキスト
      * @param {Object}   attribute - 属性
      * @param {Array}    class   - class
      */
      const createNode = (options) => {
        var element = document.createElement(options.tag);
        if (options.class) {
          options.class.forEach(function (className) {
            element.classList.add(className);
          });
        }
        if (options.attribute) {
          options.attribute.forEach(function (attribute) {
            element.setAttribute(attribute.name, attribute.value);
          });
        }
        if (options.text) {
          element.innerText = options.text;
        }
        return element;
      }

      estatesListElements.forEach(function (estatesListElement) {

        const query = estatesListElement.getAttribute('data-query');
        const oReq = new XMLHttpRequest();
      
        /**
         * on load api of search estate
         */
        const transferComplete = () => {
          const data = JSON.parse(oReq.response);
          data.items.forEach(function (item) {

            // preparate elements
            const propertiesList__item = createNode({
              tag: 'li',
              class: ['propertiesList__item']
            });
            const propertiesList__inner = createNode({
              tag: 'a',
              attribute: [
                {
                  name: 'href',
                  value: item.url
                }
              ],
              class: ['propertiesList__inner']
            });
            const propertiesList__figure = createNode({
              tag: 'figure',
              class: ['propertiesList__figure']
            });
            const img = createNode({
              tag: 'img',
              attribute: [
                {
                  name: 'src',
                  value: item.thumbnail
                },
                {
                  name: 'alt',
                  value: '物件写真'
                }
              ]
            });
            const propertiesList__info = createNode({
              tag: 'div',
              class: ['propertiesList__info']
            });
            const propertiesList__title = createNode({
              tag: 'h4',
              class: ['propertiesList__title'],
              text: item.name
            });
            const propertiesList__spec = createNode({
              tag: 'div',
              class: ['propertiesList__spec']
            });
            const propertiesList__room = createNode({
              tag: 'p',
              class: ['propertiesList__room'],
              text: item.room
            });
            const propertiesList__separator = createNode({
              tag: 'div',
              class: ['propertiesList__separator']
            });
            const propertiesList__price = createNode({
              tag: 'p',
              class: ['propertiesList__price'],
              text: item.price
            });
            const propertiesList__access = createNode({
              tag: 'p',
              class: ['propertiesList__access'],
              text: item.access
            });

            // assemble elements
            propertiesList__figure.appendChild(img);
            propertiesList__spec.appendChild(propertiesList__room);
            propertiesList__spec.appendChild(propertiesList__separator);
            propertiesList__spec.appendChild(propertiesList__price);
            propertiesList__info.appendChild(propertiesList__title);
            propertiesList__info.appendChild(propertiesList__spec);
            propertiesList__info.appendChild(propertiesList__access);
            propertiesList__inner.appendChild(propertiesList__figure);
            propertiesList__inner.appendChild(propertiesList__info);
            propertiesList__item.appendChild(propertiesList__inner);

            // append to list element
            estatesListElement.appendChild(propertiesList__item);

          });
        }
      
        const transferFailed = () => {
          console.error(oReq.response);
        }

        // load 
        oReq.addEventListener('load', transferComplete);
        oReq.addEventListener('error', transferFailed);
        oReq.open('GET', '/web/api.php?' + encodeURI(query));
        oReq.send();

    });
      
    }

  });

}